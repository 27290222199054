// Imports
import { cookieGetToken, users } from "@apis";
import { CheckError } from "@utils";

// Patch Profile
export const deleteUser = async (id, message) => {
  await fetch(users + id, {
    method: "DELETE",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
  })
    .then((response) =>
      CheckError(
        response,
        message,
        "Felhasználó sikeresen törölve.",
        "Probléma történt."
      )
    )
    .catch((error) => console.log(error));
};
