// Imports
import { Button } from "@components";
import { SadPin } from "@icons";

export const TableEmpty = (props) => {
  return (
    <div className="datatable__empty">
      <div className="datatable__empty__icon">{props.icon || <SadPin />}</div>
      <div className="datatable__empty__content">
        <h4>{props.headline}</h4>
        <p>{props.message}</p>
      </div>
      <a href="https://map.envimap.hu/">
        <Button>Tovább a térképhez</Button>
      </a>
    </div>
  );
};
