// Imports
import { cookieGetToken, getDomainUsers, urls } from "@apis";
import { SetDomain } from "@store";

// Get Domain details
export const getDomain = async (domain_id) => {
  await fetch(urls.domain + domain_id, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw Error(response.statusText);
      }
    })
    .then((actualData) => {
      // Set returned requests data
      SetDomain(actualData);
      getDomainUsers();
    })
    .catch((error) => {
      console.log(error);
    });
};
