import { proxy } from "valtio";

// STORE
export const StoreDomain = proxy({
  available_services: [],
  domain_id: "",
  domain_name: "",
  company_name: "",
  company_postal: 0,
  company_city: "",
  company_address: "",
  company_vat: "",
  on_trial: true,
  is_active: false,
  is_super_domain: true,
  parent_id: "",
  cross_sharing: false,
  users: [],
});

// Set domain data from api data
export const SetDomain = (data) => {

  // Keys that need to be stored
  const domainDataToStore = [
    "domain_id",
    "domain_name",
    "company_name",
    "company_postal",
    "company_city",
    "company_address",
    "company_vat",
    "on_trial",
    "is_active",
    "is_super_domain",
    "parent_id",
    "cross_sharing",
  ];

  // Store the values
  domainDataToStore.forEach((key) => {
    StoreDomain[key] = data[key];
  });

  StoreDomain.available_services = JSON.parse(data.available_services);
};

// Set domain users
export const SetUsers = (data) => {
  let usersArray = [];

  // Mapping data and add to array in store
  data.map((user) => {
    let userObj = {
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      is_admin: user.is_admin,
    };
    return usersArray.push(userObj);
  });

  // Store the values
  StoreDomain.users = usersArray;
};

// Set domain users role
export const SetUsersRole = (id, value) => {
  let selectedUser = StoreDomain.users.filter((user) => user.id === id);
  if (value === "admin") selectedUser.is_admin = true;
  if (value === "user") selectedUser.is_admin = false;
};

// Set domain data from api data
export const UpdateDomain = (data) => {
  // Keys that need to be stored
  const domainDataToStore = [
    "company_name",
    "company_postal",
    "company_city",
    "company_address",
    "company_vat",
  ];

  // Store the values
  domainDataToStore.forEach((key) => {
    StoreDomain[key] = data[key];
  });
};
