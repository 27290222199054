// Imports
import { ExportToCsv } from "export-to-csv-fix-source-map";
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { CardRequest, useMultiFilter } from "@components";
import { StoreGlobal, StoreRequests } from "@store";
import { Table, TableEmpty, TableRequests } from "@tables";
import { getServiceInfo } from "@utils";

export const RequestsPage = () => {
  const { requests } = useSnapshot(StoreRequests);
  const { domain_name } = useSnapshot(StoreGlobal.domain);
  const [csvData, setCsvData] = useState([]);

  // Filtering
  const [filterText, setFilterText] = useState("");
  const filterableColums = ["id", "created_at", "domain_name", "resolved_addr"];
  const [filters, setFilters] = useState({});
  const filteredData = useMultiFilter(
    requests,
    filterText,
    filters,
    filterableColums
  );

  // Transform data to CSV formating
  useEffect(() => {
    if (requests) {
      let csvDataArray = [];

      const buildingFeatureNames = [
        "Épületmodell",
        "Épületmodell tetőobjektumokkal",
        "Épületmodell tetőobjektumokkal és napelem kiosztással",
      ];

      const statusTranslate = {
        Pending: "Feldolgozás alatt",
        Available: "Elérhető",
        Denied: "Nem teljesíthető",
      };

      requests.map((request) => {
        let requestCSVObj = {
          id: `${getServiceInfo(request.service).id}-${request.id}`,
          created_at: request.created_at,
          resolved_addr: request.resolved_addr,
          coords: `${request.coords.lat}, ${request.coords.lng}`,
          service: request.service,
          building_type: request.building_type,
          building_features: buildingFeatureNames[request.building_features],
          status: statusTranslate[request.status],
          url: `http://map.envimap.hu/teto?tet=${request.id}`,
        };
        return csvDataArray.push(requestCSVObj);
      });

      setCsvData(csvDataArray);
    }
  }, [requests]);

  // CSV options
  const date = new Date();
  const csvOptions = {
    filename: `EnviMAP_Adatigények-${domain_name}-${date.getFullYear()}_${
      date.getMonth() + 1
    }_${date.getDate()}`,
    showLabels: true,
    useTextFile: false,
    useBom: true,
    headers: [
      "Azonosító",
      "Igénylés dátuma",
      "Cím",
      "Koordináták",
      "Szolgáltatás",
      "Épület típusa",
      "Igényelt adatok",
      "Státusz",
      "URL",
    ],
  };
  const csvExporter = new ExportToCsv(csvOptions);

  return (
    <Table
      title="Megvásárolt téradatai"
      filterText={filterText}
      setFilterText={setFilterText}
      setFilters={setFilters}
      filters={filters}
      selects
      action={() => csvExporter.generateCsv(csvData)}
      actionLabel="CSV"
    >
      {requests.length !== 0 ? (
        <>
          <TableRequests data={filteredData} />
          <CardRequest data={filteredData} />
        </>
      ) : (
        <TableEmpty
          headline="Nincsenek megvásárolt téradai!"
          message="A térkép felület használatával percek alatt leadhatja igényeit amelyek állapotát itt nyomonkövetheti."
        />
      )}
    </Table>
  );
};
