// Imports
import { Done } from "@icons";

export const CardPackage = ({
  active,
  title,
  price,
  subPrice,
  priceUnit,
  list,
}) => {
  return (
    <div className={`modal__content-card ${active ? "active" : ""}`}>
      <div className="modal__content-card__header">
        <h5>{title}</h5>
      </div>
      <div className="modal__content-card__body">
        <div className="modal__content-card__body-list">
          {list.map((element, index) => {
            return (
              <div className="modal__content-card__body-list__item" key={index}>
                <div>
                  <Done color="green" />
                </div>
                <p>{element}</p>
              </div>
            );
          })}
        </div>
        <div className="modal__content-card__body-price">
          <h4>
            {price} {priceUnit}
          </h4>
          <h5>{subPrice}</h5>
          <div>
            <button>{active ? "Aktív csomag" : "Kiválasztás"}</button>
          </div>
        </div>
      </div>
    </div>
  );
};
