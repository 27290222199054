// Imports
import { proxy } from "valtio";
import { StoreDomain } from "./Domain";
import { StoreRequests } from "./Requests";
import { StoreUser } from "./User";

// GLOBAL STORE
export const StoreGlobal = proxy({
  userLoggedIn: false,
  forgottenSent: false,
  user: StoreUser,
  domain: StoreDomain,
  requests: StoreRequests,
});

// Set user logged in
export const SetUserLoggedIn = (state) => {
  StoreGlobal.userLoggedIn = state;
  if (state) {
    SetForgottenSent(false);
  }
};

// Set user logged in
export const SetForgottenSent = (state) => {
  StoreGlobal.forgottenSent = state;
};
