// Imports
import { useEffect, useState } from "react";
import { getCounty } from "@utils";

export const useMultiFilter = (
  data,
  searchQuery,
  filters,
  filterableColums
) => {
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    let filtered = data;

    // Filter by search query
    if (searchQuery) {
      filtered = filtered.filter((item) => {
        for (const key of filterableColums) {
          if (
            item[key] &&
            item[key]
              .toString()
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
          ) {
            return item;
          }
        }
        return null;
      });
    }

    // Filter by other criteria in filter object
    if (filters) {
      Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (value !== "") {
          filtered = filtered.filter((item) => {
            // Check if county matches
            if (key === "county" && item.resolved_addr) {
              return getCounty(item.resolved_addr) === value;
            }
            return item[key] === value;
          });
        }
      });
    }

    setFilteredData(filtered);
  }, [data, searchQuery, filters]);

  return filteredData;
};
