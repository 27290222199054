// Imports
import { cookieGetToken, urls } from "@apis";
import { SetUsers } from "@store";

// Get Domain details
export const getDomainUsers = async () => {
  await fetch(urls.getUsers, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw Error(response.statusText);
      }
    })
    .then((actualData) => SetUsers(actualData))
    .catch((error) => console.log(error));
};
