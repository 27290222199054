import imgForest from "@images/forest-thumbnail.jpg";
import imgSolar from "@images/solar-thumbnail.jpg";
import imgStore from "@images/store-thumbnail.jpg";

const serviceData = {
  GeoStore: {
    link: "",
    name: "EnviMAP© Téradatbank",
    thumbnail: imgStore,
    id: "env",
  },
  Solar: {
    link: "teto",
    name: "EnviMAP© Tető",
    thumbnail: imgSolar,
    id: "tet",
  },
  Forest: {
    link: "erdo",
    name: "EnviMAP© Erdő",
    thumbnail: imgForest,
    id: "erd",
  },
  default: {
    link: "",
    name: "Undefined",
    thumbnail: null,
    id: null,
  },
};

export const getServiceInfo = (service) => {
  return serviceData[service] || serviceData.default;
};