// Imports
import { urls } from "@apis";
import { SetForgottenSent } from "@store";
import { CheckError } from "@utils";

// Forgotten password
export const postForgotPassword = async (body, message) => {
  await fetch(urls.forgotPassword, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: body.email,
    }),
  })
    .then((response) =>
      CheckError(
        response,
        message,
        "Jelszómódosítás sikeres!",
        "A megadott e-mail cím nincsen regisztrálva!"
      )
    )
    .then(() => {
      SetForgottenSent(true);
    })
    .catch((error) => {
      console.log(error);
    });
};
