// Imports
import { cookieGetToken, urls } from "@apis";
import { UpdateDomain } from "@store";
import { CheckError } from "@utils";

// Patch Domain
export const patchDomain = async (domain_id, body, message) => {
  await fetch(urls.domain + domain_id, {
    method: "PATCH",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
    body: JSON.stringify(body),
  })
    .then((response) =>
      CheckError(
        response,
        message,
        "Domain beállítások sikeresen frissítve.",
        "Probléma történt a domain beállítások frissítése közben."
      )
    )
    .then(() => {
      // Update Store
      UpdateDomain(body);
    })
    .catch((error) => {
      console.log(error);
    });
};
