// Imports
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { patchDomain } from "@apis";
import { Input, SegmentedControl, useToastContext } from "@components";
import { Information } from "@icons";
import { Modal } from "@modals";
import { StoreGlobal } from "@store";

export const ModalDomain = ({ isModalOpen }) => {
  const {
    domain_name,
    domain_id,
    company_name,
    company_vat,
    company_postal,
    company_city,
    company_address,
    on_trial,
    cross_sharing,
  } = useSnapshot(StoreGlobal.domain);
  const addToast = useToastContext();

  const [formEdited, setFormEdited] = useState(false);

  // Form Data
  const [formData, setFormData] = useState({
    companyName: "",
    companyVat: "",
    companyPostal: "",
    companyCity: "",
    companyAddress: "",
    crossSharing: "disabled",
  });

  // Populate form data with stored domain data
  useEffect(() => {
    setFormData((prevData) => {
      return {
        ...prevData,
        companyName: company_name,
        companyVat: company_vat,
        companyPostal: company_postal,
        companyCity: company_city,
        companyAddress: company_address,
        crossSharing: cross_sharing ? "enabled" : "disabled",
      };
    });
  }, [
    company_name,
    company_vat,
    company_postal,
    company_city,
    company_address,
    cross_sharing,
  ]);

  // Handle input change
  const handleInput = (e) => {
    setFormData((prevData) => {
      const { name, value, type, checked } = e.target;
      return {
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    setFormEdited(true);
  };

  // Handle input change
  const handleSubmit = (e) => {
    e.preventDefault();

    // Update profile if data has changed
    if (formEdited) {
      // Create body
      let body = {
        domain_name: domain_name,
        company_name: formData.companyName,
        company_vat: formData.companyVat,
        company_postal: formData.companyPostal,
        company_city: formData.companyCity,
        company_address: formData.companyAddress,
        on_trial: on_trial,
        cross_sharing: formData.crossSharing === "disabled" ? false : true,
      };

      // Initialize API
      patchDomain(domain_id, body, addToast);
    }
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      title={"Domain beállítások"}
      hasFooter={true}
    >
      <form id="modalForm" onSubmit={handleSubmit}>
        <div className="modal__content__section">
          <h4>Téradatok megosztása</h4>
          <div className="modal__content__section__group">
            <SegmentedControl
              segments={[
                {
                  label: "Kikapcsolva",
                  value: "disabled",
                  activeColor: "red",
                },
                {
                  label: "Bekapcsolva",
                  value: "enabled",
                  activeColor: "green",
                },
              ]}
              name={"crossSharing"}
              onChange={handleInput}
              value={formData.crossSharing}
            />

            <div className="modal__information">
              <Information />
              <span>
                {formData.crossSharing === "disabled"
                  ? "A felhasználók csak saját téradat igényeiket látják. Az adminisztrátorok minden igényhez hozzáférnek."
                  : "A domain minden felhasználója és adminisztrátora látja egymás téradat igényét."}
              </span>
            </div>
          </div>
        </div>

        <div className="modal__content__section">
          <h4>Számlázási adatok</h4>
          <div className="modal__content__section__group">
            <Input
              label={"Cég"}
              type={"text"}
              name={"companyName"}
              onChange={handleInput}
              value={formData.companyName}
            />
            <Input
              label={"Adószám"}
              type={"text"}
              name={"companyVat"}
              onChange={handleInput}
              value={formData.companyVat}
            />
            <div className="modal__content__section__group-inline">
              <Input
                label={"IRSZ"}
                type={"number"}
                name={"companyPostal"}
                onChange={handleInput}
                value={formData.companyPostal}
              />
              <Input
                label={"Város"}
                type={"text"}
                name={"companyCity"}
                onChange={handleInput}
                value={formData.companyCity}
              />
            </div>
            <Input
              label={"Cím"}
              type={"text"}
              name={"companyAddress"}
              onChange={handleInput}
              value={formData.companyAddress}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};
