// Imports
import { cookieGetToken, urls } from "@apis";

// Check error
const CheckError = (response, message) => {
  if (response.status === 200) {
    message(["success", "A felhasználó meghívása került."]);

    return response.json();
  } else {
    // Send error message to user
    message(["error", "Probléma a felhasználó meghívása közben."]);
    throw Error(response.statusText);
  }
};

// getProfile
export const inviteNewUser = async (body, message, email, setFormData) => {
  await fetch(urls.inviteUser, {
    method: "POST",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + cookieGetToken(),
      "Content-Type": "application/json",
    },
    body: body,
  })
    .then((response) => CheckError(response, message))
    .then(() => {
      // Clear fields
      setFormData({ inviteEmail: "" });
    })
    .catch((error) => console.log(error));
};
