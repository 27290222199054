export const SelectFilter = ({ selected, label, options, name, onChange }) => {
  return (
    <div className="select-filter">
      <label>
        {label}
        <select name={name} value={selected} onChange={onChange}>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};
