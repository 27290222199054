// Imports
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { Button, SearchBar, SelectFilter } from "@components";
import { StoreRequests } from "@store";
import { compareValues, getCounty } from "@utils";

// React Data Table Defaults
export const tableBreakpoints = {
  TL: 980,
  DS: 1200,
  DL: 1568,
};
export const columWidth = {
  small: "4rem",
  medium: "8rem",
  normal: "10rem",
  large: "12rem",
  dateCount: "6rem",
  dateDate: "9rem",
  dateTime: "11rem",
  arrow: "4.5rem",
};
export const paginationComponentOptions = {
  rangeSeparatorText: "/",
  selectAllRowsItem: true,
  noRowsPerPage: true,
};

export const Table = ({
  title,
  children,
  action = false,
  actionLabel,
  filterText,
  setFilterText,
  filters,
  setFilters,
  selects,
}) => {
  const { requests: requestList } = useSnapshot(StoreRequests);
  const [options, setOptions] = useState([]);

  // Set new filters
  useEffect(() => {
    const availableMegyeOptions = [
      ...new Set(requestList.map((req) => getCounty(req.resolved_addr))),
    ];
    let newOptions = [];

    // Create array of objects for select component
    availableMegyeOptions.map((county) => {
      let obj = { value: county, label: county };
      return newOptions.push(obj);
    });

    // Sorting
    newOptions.sort(compareValues("label"));
    // Add all option to the first place
    newOptions.unshift({ value: "", label: "Összes megye" });
    // Set new value
    setOptions(newOptions);
  }, [requestList]);

  const handleFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  return (
    <section className="datatable">
      <div className="datatable__header">
        <h2>{title}</h2>
        <div className="datatable__header__actions">
          {selects && (
            <SelectFilter
              name="county-select"
              options={options}
              value={filters.status}
              onChange={(e) => handleFilterChange("county", e.target.value)}
            />
          )}

          <SearchBar
            placeholder="Keresés..."
            filterText={filterText}
            setFilterText={setFilterText}
          />
          {action && (
            <Button
              buttonType="neutral"
              actionType="button"
              onClickEvent={action}
            >
              {actionLabel}
            </Button>
          )}
        </div>
      </div>
      {children}
    </section>
  );
};
