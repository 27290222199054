// Imports
import { proxy } from "valtio";
import { StoreGlobal } from "./Global";

// STORE
export const StoreRequests = proxy({
  requests_length: 0,
  last_request: {
    id: "",
    created_at: "",
    status: "",
  },
  requests: [
    {
      id: "",
      service: "",
      resolved_addr: "",
      coords: {
        lat: 0,
        lng: 0,
      },
      building_type: "",
      building_features: "",
      status: "",
      created_at: "",
      updated_at: "",
      user_id: "",
      domain_id: "",
    },
  ],
});

// Set requests data length api data
export const SetRequestsLength = (length) => {
  StoreRequests.requests_length = length;
};

// Set last request from api data
export const SetLastRequests = (request) => {
  StoreRequests.last_request = {
    id: request.id,
    created_at: request.created_at,
    status: request.status,
  };
};

// Set requests data from api data
export const SetRequests = (data) => {
  let prevRequestsArray = [];

  // Mapping data and add to array in store
  data.map((request) => {
    let requestObj = {
      id: request.Map.request_id,
      service: request.Map.service,
      resolved_addr: request.Map.resolved_addr,
      coords: {
        lat: request.Map.lat,
        lng: request.Map.lon,
      },
      building_type: request.Map.building_type,
      building_features: request.Map.building_features,
      status: request.Map.status,
      created_at: formatDate(request.Map.created_at),
      updated_at: formatDate(request.Map.updated_at),
      user_id: request.User?.id,
      partner_id: request.Partner?.id,
      domain_name: request.Domain.domain_name,
    };
    return prevRequestsArray.push(requestObj);
  });

  // Filter requests if user is not admin and cross sharing is disabled
  if (!StoreGlobal.user.is_admin && !StoreGlobal.domain.cross_sharing) {
    prevRequestsArray = prevRequestsArray.filter(
      (request) => request.user_id === StoreGlobal.user.id
    );
  }

  StoreRequests.requests = prevRequestsArray;

  // Update requests number in store
  SetRequestsLength(prevRequestsArray.length);

  // Update last requests in store
  if (prevRequestsArray.length) {
    const latest = prevRequestsArray.reduce(function (r, a) {
      return r.created_at > a.created_at ? r : a;
    });
    SetLastRequests(latest);
  }
};

// Helper function to format date
const formatDate = (unFormatedDate) => {
  // Utility functions
  const checkLength = (unformatedDateNumber) => {
    let formatedDate = unformatedDateNumber.toString();
    if (formatedDate.length < 2) formatedDate = "0" + formatedDate;
    return formatedDate;
  };

  // Return formated date
  let oldFormatedDate = unFormatedDate.slice(0, 16);
  oldFormatedDate = oldFormatedDate.replaceAll("T", " ");
  oldFormatedDate = oldFormatedDate.replaceAll("-", ".");

  const arrayDate = oldFormatedDate.split(" ");
  const arrayYearMonthDay = arrayDate[0];
  const arrayHours = checkLength(parseInt(arrayDate[1].split(":")[0]) + 1);
  const arrayMinutes = arrayDate[1].split(":")[1];

  // Create expected date format
  const formatedDate = `${arrayYearMonthDay}. ${arrayHours}:${arrayMinutes}`;

  return formatedDate;
};
