export const ContainerFluid = ({ children, justSmall }) => {
  return (
    <div
      className={`--container-fluid ${
        justSmall ? "--container-fluid-just-small" : ""
      }`}
    >
      {children}
    </div>
  );
};
