export const Select = ({ selected, label, options, onChange }) => {
  return (
    <div className="select">
      <label>
        {label}
        <select value={selected} onChange={onChange}>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};
