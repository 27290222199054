// Imports
import { cookieGetToken, urls } from "@apis";
import { UpdateUser } from "@store";
import { CheckError } from "@utils";

// Patch Profile
export const patchProfile = async (body, message) => {
  await fetch(urls.profile, {
    method: "PATCH",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
    body: JSON.stringify(body),
  })
    .then((response) =>
      CheckError(
        response,
        message,
        "A személyes adatok frissítve lettek.",
        "Probléma történt a személyes adatok frissítése közben."
      )
    )
    .then(() => UpdateUser(body))
    .catch((error) => console.log(error));
};
