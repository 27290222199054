// Imports
import { SadPin } from "@icons";

export const TableNoResults = (props) => {
  return (
    <div className="datatable__noresults">
      <div className="datatable__noresults__icon">
        {props.icon || <SadPin />}
      </div>
      <h5>{props.text}</h5>
    </div>
  );
};
