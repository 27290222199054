// Imports
import { FileDefault, Help } from "@icons";

export const Footer = () => {
  return (
    <footer>
      <div className="footer__legal">
        <span>{new Date().getFullYear()} - © Envirosense Hungary Kft.</span>
      </div>
      <ul className="footer__links">
        <li>
          <a
            href="https://teto.envimap.hu/ENVIROSENSE_HUNGARY_Kft-ASZF-ENVIMAP-Teto.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <FileDefault />
            <span className="span--bold">Általános Szerződési Feltételek</span>
          </a>
        </li>
        <li>
          <a href="mailto:info@envimap.hu" target="_blank" rel="noreferrer">
            <Help />
            <span className="span--bold">Segítségkérés</span>
          </a>
        </li>
      </ul>
    </footer>
  );
};
