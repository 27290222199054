// Imports
import { cookieGetToken, urls } from "@apis";
import { SetRequests } from "@store";

// Get Requests for the logged in users domain
export const getRequests = async () => {
  await fetch(urls.requests, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw Error(response.statusText);
      }
    })
    .then((actualData) => {
      // Set returned requests data
      SetRequests(actualData);
    })
    .catch((error) => console.log(error));
};
