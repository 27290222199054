// Imports
import { useContext, useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import {
  Accordion,
  Button,
  CardServices,
  ModalContext,
  Progressbar,
} from "@components";
import { ArrowDown } from "@icons";
import { StoreGlobal } from "@store";
import { getServiceInfo } from "@utils";


const DashboardServices = () => {
  const { requests } = useSnapshot(StoreGlobal.requests);
  const { available_services } = useSnapshot(StoreGlobal.domain);
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (Object.keys(available_services).length !== 0) {
      setServices(
        available_services.map((service) => {
          const matchingRequests = requests.filter(
            (request) => request.service === service
          );
          const lastRequest =
            matchingRequests.length !== 0
              ? matchingRequests.reduce((r, a) =>
                  r.created_at > a.created_at ? r : a
                )
              : "";
          const serviceInfo = getServiceInfo(service);
          return {
            title: serviceInfo.name,
            img: serviceInfo.thumbnail,
            link: `https://map.envimap.hu/${serviceInfo.link}`,
            status: lastRequest.status,
            last_request: lastRequest,
            requests_length: matchingRequests.length,
          };
        })
      );
      setTimeout(() => setIsLoading(false), 500);
    }
  }, [available_services, requests]);

  return (
    <div className="dashboard__services">
      <h4>Elérhető szolgáltatásai</h4>
      <div className="dashboard__services-list">
        {services.map((element, index) => {
          return (
            <CardServices
              key={index}
              title={element.title}
              img={element.img}
              dataPurchased={element.requests_length}
              lastQuery={element.last_request.created_at}
              badgeStatus={element.last_request.status}
              link={element.link}
              isLoading={isLoading}
            />
          );
        })}
      </div>
    </div>
  );
};

const DashboardDetails = () => {
  const { first_name, last_name, is_admin } = useSnapshot(StoreGlobal.user);
  const { domain_name, on_trial } = useSnapshot(StoreGlobal.domain);
  const { requests_length } = useSnapshot(StoreGlobal.requests);
  const { modalOpen, modalType } = useContext(ModalContext);

  return (
    <div className="dashboard__details">
      <Accordion>
        <div className="dashboard__details__header">
          <h4>Profil</h4>
          <div className="dashboard__details__header--chevron">
            <ArrowDown color={"green"} />
          </div>
          <div className="dashboard__details__header--action">
            <Button
              asLink={"true"}
              onClickEvent={(e) => {
                e.preventDefault();
                modalOpen(true);
                modalType("profile");
              }}
            >
              Profil beállítások
            </Button>
          </div>
        </div>
        <div>
          <div className="dashboard__details__content">
            <div className="dashboard__details__content--block">
              <h3>
                {last_name} {first_name}
              </h3>
              <span className="span--bold">
                {is_admin ? "Adminisztrátor" : "Felhasználó"}
              </span>
            </div>
          </div>
        </div>
      </Accordion>

      <hr />

      <Accordion>
        <div className="dashboard__details__header">
          <h4>Domain</h4>
          <div className="dashboard__details__header--chevron">
            <ArrowDown color={"green"} />
          </div>
          <div className="dashboard__details__header--action">
            {is_admin && (
              <Button
                asLink={"true"}
                onClickEvent={(e) => {
                  e.preventDefault();
                  modalOpen(true);
                  modalType("domain");
                }}
              >
                Domain beállítások
              </Button>
            )}
          </div>
        </div>
        <div>
          <div className="dashboard__details__content">
            <div className="dashboard__details__content--block">
              <h5>{domain_name}</h5>
              <h3>{on_trial ? "Demo" : "Korlátlan"}</h3>
            </div>
            {on_trial && (
              <div className="dashboard__details__content--block">
                <div className="dashboard__details__content--rowblock">
                  <span className="span--bold">Elérhető lekérdezések</span>
                  <span className="span--bold">{3 - requests_length} db</span>
                </div>
                <Progressbar max={3} current={requests_length} />
              </div>
            )}
          </div>
        </div>
      </Accordion>
    </div>
  );
};

export const DashboardPage = () => {
  return (
    <section>
      <div className="dashboard-layout">
        <DashboardDetails />
        <DashboardServices />
      </div>
    </section>
  );
};
