export const Button = ({
  children,
  type = "button",
  actionType = "button",
  buttonType,
  asLink = false,
  onClickEvent,
}) => {
  if (type === "button") {
    if (asLink) {
      return (
        <button
          onClick={onClickEvent}
          type={actionType}
          className="button button-link"
        >
          {children}
        </button>
      );
    } else if (buttonType === "danger") {
      return (
        <button
          onClick={onClickEvent}
          type={actionType}
          className="button button-danger"
        >
          {children}
        </button>
      );
    } else if (buttonType === "neutral") {
      return (
        <button
          onClick={onClickEvent}
          type={actionType}
          className="button button-neutral"
        >
          {children}
        </button>
      );
    } else {
      return (
        <button onClick={onClickEvent} type={actionType} className="button">
          {children}
        </button>
      );
    }
  }
  if (type === "icon") {
    return (
      <button
        onClick={onClickEvent}
        type={actionType}
        className="button button-icon"
      >
        {children}
      </button>
    );
  }
};
