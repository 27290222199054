// Imports
import { useEffect, useRef, useState } from "react";
import { useSnapshot } from "valtio";
import { convertUserRole, deleteUser, inviteNewUser } from "@apis";
import { Input, Select, useToastContext } from "@components";
import { Bin, UserFilled } from "@icons";
import { Modal } from "@modals";
import { StoreGlobal } from "@store";

export const ModalUser = ({ isModalOpen }) => {
  const { is_admin } = useSnapshot(StoreGlobal.user);
  const { users } = useSnapshot(StoreGlobal.domain);
  const [editableUsers, setEditableUsers] = useState([]);
  const isCalledRef = useRef(false);
  const addToast = useToastContext();

  // Init users
  useEffect(() => {
    if (!isCalledRef.current) {
      isCalledRef.current = true;
      setEditableUsers([...users]);
    }
  }, [isCalledRef, users, editableUsers]);

  // Convert user to admin
  const handleSelect = (id, value) => {
    // Call API
    convertUserRole(value, id, addToast);

    // Render UI
    // Find first object that matches condition
    const selectedUser = editableUsers.find((user) => {
      return user.id === id;
    });
    const newState = editableUsers.map((obj) => {
      // If id equals 2, update country property
      if (obj.id === selectedUser.id) {
        return { ...obj, is_admin: value === "admin" ? true : false };
      }
      // Otherwise return object as is
      return obj;
    });
    setEditableUsers(newState);
  };

  // Handle delete user function
  const deleteSelectedUser = (id) => {
    // Call API
    deleteUser(id, addToast);

    // Delete user from UI
    setEditableUsers((current) =>
      current.filter((user) => {
        return user.id !== id;
      })
    );
  };

  // Handle deleting user
  const handleDeleteUser = (id) => {
    addToast([
      // Style of toast
      "error",
      // Short text of toast
      "Biztos kívánja törölni a felhasználót?",
      // Type of toast
      "action",
      // Long text of toast
      "A törlése viszavonhatatlan művelet, azonban ezen felhasználó igényei továbbra is elérhetőek maradnak a domainen belül.",
      // Action of toast
      () => deleteSelectedUser(id),
      // Action button of toast
      "Törlés megerősítése",
    ]);
  };

  return (
    <Modal isModalOpen={isModalOpen} title={"Felhasználók"} hasFooter={false}>
      {is_admin && <UserInvite />}
      <div className="modal__content__userlist">
        {editableUsers.map((user, index) => {
          return (
            <UserItem
              key={index}
              id={user.id}
              lastName={user.last_name}
              firstName={user.first_name}
              email={user.email}
              is_admin={user.is_admin}
              handleDeleteUser={handleDeleteUser}
              handleSelect={handleSelect}
            />
          );
        })}
      </div>
    </Modal>
  );
};

const UserInvite = () => {
  const addToast = useToastContext();
  const { domain_id } = useSnapshot(StoreGlobal.domain);

  // Form Data
  const [formData, setFormData] = useState({
    inviteEmail: "",
  });

  // Handle input change
  const handleInput = (e) => {
    setFormData((prevData) => {
      const { name, value, type, checked } = e.target;
      return {
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  // Handle form submition
  const handleSubmit = (e) => {
    e.preventDefault();

    // Invite user if email has been added
    if (formData.inviteEmail) {
      // Create body
      let body = JSON.stringify({
        last_name: "Meghívott",
        first_name: "felhasználó",
        email: formData.inviteEmail,
        phone: "",
        domain_id: domain_id,
        password: "string",
        is_active: true,
        is_admin: false,
      });

      // Initialize API
      inviteNewUser(body, addToast, formData.inviteEmail, setFormData);
    }
  };

  return (
    <form id="modalForm" onSubmit={handleSubmit}>
      <div className="modal__content__section section-top">
        <Input
          type={"text"}
          name={"inviteEmail"}
          placeholder={"Meghívás küldése e-mail címre"}
          onChange={handleInput}
          value={formData.inviteEmail}
        />
        <button type="submit" className="--button-gray">
          Meghívás
        </button>
      </div>
    </form>
  );
};

const UserItem = ({
  firstName,
  lastName,
  email,
  is_admin,
  id,
  handleDeleteUser,
  handleSelect,
}) => {
  return (
    <div className="modal__content__userlist__user">
      <div className="modal__content__userlist__user-row">
        <UserFilled />
        <div>
          <h5>
            {lastName} {firstName}
          </h5>
          <span>{email}</span>
        </div>
      </div>
      <div className="modal__content__userlist__user-action">
        <Select
          selected={is_admin ? "admin" : "user"}
          options={[
            { value: "admin", text: "Admin" },
            { value: "user", text: "Felhasználó" },
          ]}
          onChange={(e) => handleSelect(id, e.target.value)}
        />
        <div onClick={() => handleDeleteUser(id)}>
          <Bin color="red" />
        </div>
      </div>
    </div>
  );
};
