// Imports
import { useEffect, useState } from "react";

export const Accordion = ({ children }) => {
  const [isAccordionActive, setIsAccordionActive] = useState(true);
  const [isMediaMobile, setIsMediaMobile] = useState(true);

  const handleMedia = () => {
    setIsMediaMobile(window.innerWidth > 671 ? false : true);
  };

  useEffect(() => {
    handleMedia();
    window.addEventListener("resize", handleMedia);
  }, []);

  useEffect(() => {
    setIsAccordionActive(isMediaMobile ? false : true);
  }, [isMediaMobile]);

  return (
    <div className={`accordion ${isAccordionActive ? "active" : ""}`}>
      <div className="accordion__item">
        <div
          className="accordion__title"
          onClick={() =>
            isMediaMobile && setIsAccordionActive(!isAccordionActive)
          }
        >
          {children[0]}
        </div>
        <div
          className={`accordion__content ${isAccordionActive ? "active" : ""}`}
        >
          {children[1]}
        </div>
      </div>
    </div>
  );
};
