// Imports
import {
  ModalDomain,
  ModalPackage,
  ModalProfile,
  ModalTempEmail,
  ModalTempPassword,
  ModalUser,
} from "@modals";

export const ModalManager = ({ isModalOpen, type }) => {
  switch (true) {
    case type === "profile":
      return <ModalProfile isModalOpen={isModalOpen} />;
    case type === "users":
      return <ModalUser isModalOpen={isModalOpen} />;
    case type === "domain":
      return <ModalDomain isModalOpen={isModalOpen} />;
    case type === "packages":
      return <ModalPackage isModalOpen={isModalOpen} />;
    case type === "temppass":
      return <ModalTempPassword isModalOpen={isModalOpen} />;
    case type === "tempemail":
      return <ModalTempEmail isModalOpen={isModalOpen} />;
    default:
      break;
  }
};
