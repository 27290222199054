// Imports
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { cookieGetTempPassword, patchPassword } from "@apis";
import { Button, Input, InputPassword, useToastContext } from "@components";
import { Modal } from "@modals";
import { StoreGlobal } from "@store";

export const ModalTempEmail = ({ isModalOpen }) => {
  const { email } = useSnapshot(StoreGlobal.user);
  const [passwordError, setPasswordError] = useState();
  const addToast = useToastContext();

  // Form Data
  const [formData, setFormData] = useState({
    lastName: "",
    firstName: "",
    email: email,
    phone: "",
    tempPass: cookieGetTempPassword(),
    newPass: "",
    newPassAgain: "",
  });

  // Effect to handle form changes
  useEffect(() => {
    // Check if passwords are matching
    if (formData.newPassAgain) {
      if (formData.newPass === formData.newPassAgain) {
        setPasswordError(false);
      } else {
        setPasswordError(true);
      }
    } else {
      setPasswordError(false);
    }
  }, [formData]);

  // Handle input change
  const handleInput = (e) => {
    setFormData((prevData) => {
      const { name, value, type, checked } = e.target;
      return {
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  // Handle form submition
  const handleSubmit = (e) => {
    e.preventDefault();
    // Create body
    let profileBody = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      available_services: "string",
      company: "string",
      market_segment: "string",
      employee_number: "string",
      company_vat: "string",
      login_date: "2022-11-23T08:44:18.386983",
    };
    // Create cookie update body
    let cookieBody = {
      email: email,
      new_password: formData.newPass,
    };

    // Create cookie update body
    let passwordBody = JSON.stringify({
      old_password: formData.tempPass,
      new_password: formData.newPass,
    });
    // Initialize API
    patchPassword(passwordBody, cookieBody, addToast, profileBody);
  };

  return (
    <Modal isModalOpen={isModalOpen}>
      <form id="modalForm" onSubmit={handleSubmit}>
        <div className="modal__content__heading">
          <div className="modal__content__heading-text">
            <h2>Üdvözöljük</h2>
            <p>
              Fiókja beállításához kérjük adjon meg pár személyes adatot és
              módosítsa ideiglenes jelszavát.
            </p>
          </div>
        </div>
        <div className="modal__content__section">
          <div className="modal__content__section__group">
            <div className="modal__content__section__group-flex">
              <Input
                label={"Vezetéknév"}
                name={"lastName"}
                onChange={handleInput}
                value={formData.lastName}
                type="text"
              />
              <Input
                label={"Keresztnév"}
                name={"firstName"}
                onChange={handleInput}
                value={formData.firstName}
                type="text"
              />
            </div>
            <Input
              label={"E-mail cím"}
              name={"email"}
              onChange={handleInput}
              value={formData.email}
              type="text"
            />
            <Input
              label={"Telefonszám"}
              name={"phone"}
              onChange={handleInput}
              value={formData.phone}
              type="text"
            />
            <h4 className="modal__content__section__group-h4">Jelszó</h4>
            <InputPassword
              label={"Új jelszó"}
              name={"newPass"}
              onChange={handleInput}
              value={formData.newPass}
              error={passwordError}
            />
            <InputPassword
              label={"Új jelszó mégegyszer"}
              name={"newPassAgain"}
              onChange={handleInput}
              value={formData.newPassAgain}
              error={passwordError}
            />
          </div>
        </div>
        <div className="modal__content__footer">
          <Button actionType="submit">Mentés</Button>
        </div>
      </form>
    </Modal>
  );
};
