// Imports
import { createContext, useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { useSnapshot } from "valtio";
import { cookieGetToken, getProfile } from "@apis";
import { Container, Footer, ToastContextProvider } from "@components";
import { ModalManager } from "@modals";
import {
  BlankPage,
  DashboardPage,
  ErrorPage,
  ForgottenPasswordPage,
  LoginPage,
  RequestsPage,
} from "@pages";
import { SetUserLoggedIn, StoreGlobal } from "@store";

export const ModalContext = createContext();

export const MainRouter = (props) => {
  const { userLoggedIn } = useSnapshot(StoreGlobal);
  const { is_temp_password, first_name, last_name, phone } = useSnapshot(
    StoreGlobal.user
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");

  // Initial Effect checking login state
  useEffect(() => {
    if (cookieGetToken()) {
      getProfile();
      SetUserLoggedIn(true);
    }
  }, []);

  // Temporary password change modal handling
  useEffect(() => {
    if (
      is_temp_password &&
      first_name !== "felhasználó" &&
      last_name !== "Meghívott" &&
      phone !== ""
    ) {
      setModalType("temppass");
      setIsModalOpen(true);
    } else if (
      is_temp_password &&
      first_name === "felhasználó" &&
      last_name === "Meghívott" &&
      phone === ""
    ) {
      setModalType("tempemail");
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [is_temp_password, first_name, last_name, phone]);

  // Modal handlin effect
  useEffect(() => {
    isModalOpen ? OpenModalFunction() : (document.body.style.overflow = "");
    return () => {};
  }, [isModalOpen]);

  // Open modal function
  const OpenModalFunction = () => {
    document.body.style.overflow = "hidden";
    window.scrollTo(0, 0);
  };

  return userLoggedIn ? (
    <ToastContextProvider>
      <ModalContext.Provider
        value={{ modalOpen: setIsModalOpen, modalType: setModalType }}
      >
        <ModalManager
          type={modalType}
          isModalOpen={isModalOpen}
          setIsOpen={isModalOpen}
        />
        <Router>
          {props.children}
          <Container>
            <Routes>
              <Route path="/">
                <Route index element={<DashboardPage />} />
                <Route path="requests" element={<RequestsPage />} />
                <Route path="blank" element={<BlankPage />} />
                <Route path="*" element={<ErrorPage />} />
              </Route>
            </Routes>
          </Container>
          <Footer />
        </Router>
      </ModalContext.Provider>
    </ToastContextProvider>
  ) : (
    <ToastContextProvider>
      <Router>
        <Routes>
          <Route path="/">
            <Route index element={<LoginPage />} />
            <Route path="forgotten" element={<ForgottenPasswordPage />} />
            <Route path="*" element={<LoginPage />} />
          </Route>
        </Routes>
      </Router>
    </ToastContextProvider>
  );
};
