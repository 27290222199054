// Imports
import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSnapshot } from "valtio";
import { cookieRemoveToken } from "@apis";
import { ContainerFluid, ModalContext } from "@components";
import {
  ArrowDropDown,
  ArrowForward,
  Hamburger,
  HamburgerClose,
  Logo,
  User,
} from "@icons";
import { StoreDomain, StoreGlobal, SuperDomainData } from "@store";

const NavLinks = [
  {
    text: "Dashboard",
    link: "/",
  },
  {
    text: "Téradataim",
    link: "/requests",
  },
];

const ProfileLinks = [
  {
    text: "Profil",
    link: "profile",
    adminOnly: false,
  },
  {
    text: "Felhasználók",
    link: "users",
    adminOnly: true,
  },
  {
    text: "Domain",
    link: "domain",
    adminOnly: true,
  },
];

const ProfileMenu = () => {
  const { is_admin } = useSnapshot(StoreGlobal.user);

  const handleLogout = (e) => {
    e.preventDefault();

    // Remove usertoken from cookie
    cookieRemoveToken();

    // Redirect logged out user to envimap
    window.location.href = 'https://envimap.hu';
  };

  const { modalOpen, modalType } = useContext(ModalContext);
  return (
    <div className="header__content__profile-menu">
      <ul>
        {ProfileLinks.map((element, index) => {
          if (!element.adminOnly || (element.adminOnly && is_admin)) {
            return (
              <li key={index}>
                <NavLink
                  onClick={(e) => {
                    e.preventDefault();
                    modalOpen(true);
                    modalType(element.link);
                  }}
                  to={element.link}
                >
                  {element.text}
                </NavLink>
              </li>
            );
          } else {
            return null;
          }
        })}
        <hr />
        <li>
          <a href="/" onClick={(e) => handleLogout(e)}>
            Kijelentkezés
            <ArrowForward />
          </a>
        </li>
      </ul>
    </div>
  );
};

const NavbarOverlay = ({ menuOpen, setMenuOpen }) => {
  return (
    <div
      className={
        menuOpen
          ? "header__mobile-overlay header__mobile-overlay-active"
          : "header__mobile-overlay"
      }
      onClick={() => {
        setMenuOpen(!menuOpen);
      }}
    ></div>
  );
};

export const Navbar = () => {
  const { is_super_domain, parent_id, domain_id } = useSnapshot(StoreDomain);
  const [menuOpen, setMenuOpen] = useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);

  useEffect(() => {
    menuOpen ? OpenModalFunction() : (document.body.style.overflow = "");
    return () => {};
  }, [menuOpen]);

  const OpenModalFunction = () => {
    document.body.style.overflow = "hidden";
  };

  const superdomain = is_super_domain ? domain_id : parent_id;
  const superDomainCustoms = SuperDomainData.find((data) =>
    data.domain_id === superdomain
  );

  return (
    <>
      <ContainerFluid>
        <div className="header__content">
          <NavbarOverlay menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          <div className="header__content__left">
            <div className="header__content__left__logo">
              <a href="https://envimap.hu/">
                <Logo />
              </a>
              {superDomainCustoms && (
                <>
                  <span>+</span>
                  {superDomainCustoms.domainLogo}
                </>)}
            </div>
            <NavLink to="/">
              <h3 className="header__content__left__title">Személyes fiók</h3>
            </NavLink>
          </div>
          <nav
            className={`header__content__nav ${
              menuOpen ? "header__content__nav-menu-open" : ""
            }`}
          >
            <ul>
              <div className="header__content__nav-close">
                <button
                  className="header__content__nav-close-button"
                  onClick={() => {
                    setMenuOpen(!menuOpen);
                  }}
                >
                  <HamburgerClose />
                </button>
              </div>
              {NavLinks.map((element, index) => {
                return (
                  <li key={index}>
                    <NavLink
                      to={element.link}
                      onClick={() => setMenuOpen(false)}
                    >
                      {element.text}
                    </NavLink>
                  </li>
                );
              })}
              <li>
                <button
                  className="header__content-profile-button"
                  onClick={() => {
                    setProfileMenuOpen(!profileMenuOpen);
                  }}
                >
                  <User />
                  <ArrowDropDown />
                </button>
                {profileMenuOpen ? <ProfileMenu /> : ""}
              </li>
            </ul>
            <div className="header__content__nav-mobile-menu">
              <ProfileMenu />
            </div>
          </nav>
          <div className="header__content__toggle">
            <button
              className="header__content__toggle__nav-hamburger-button"
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
            >
              <Hamburger />
            </button>
          </div>
        </div>
      </ContainerFluid>
    </>
  );
};
