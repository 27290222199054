// Imports
import { Badge } from "@components";
import { ArrowForward } from "@icons";
import { TableNoResults } from "@tables";

export const CardRequest = ({ data }) => {
  return (
    <div className="datatable__cards">
      {data.length ? (
        <>
          {data.map((element, index) => {
            return (
              <a key={index} href={`http://map.envimap.hu/teto?tet=${element.id}`}>
                <div className="card" key={index}>
                  <div className="card__content">
                    <div className="card__content-head">
                      <h5>TET-{element.id}</h5>
                      <Badge status={element.status} type="request" />
                    </div>
                    <ul className="card__content-details">
                      <li>{element.resolved_addr.formatted}</li>
                      <li>
                        <span className="span--bold">{element.created_at}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="card__link">
                    <ArrowForward />
                  </div>
                </div>
              </a>
            );
          })}
        </>
      ) : (
        <TableNoResults text="Nincsenek a keresésnek megfelelő adatigényei" />
      )}
    </div>
  );
};
