// Check error
export const CheckError = (
  response,
  message,
  successMessageText,
  errorMessageText
) => {
  if (response.status === 200) {
    message(["success", successMessageText]);
    return response.json();
  } else {
    // Send error message to user
    message(["error", errorMessageText]);
    throw Error(response.statusText);
  }
};
