import { GaInit } from "@apis";
import { Header, MainRouter } from "@components";

const App = () => {

  // Initialize Google Analytics
  GaInit();

  return (
    <MainRouter>
      <Header />
    </MainRouter>
  );
};

export default App;
