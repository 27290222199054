// Imports
import { GaEventLogin, cookieGetToken, getInit, urls } from "@apis";
import { SetUser, SetUserLoggedIn } from "@store";

// Check error
const CheckError = (response) => {
  if (response.status === 200) {
    return response.json();
  } else {
    // Set user logged out
    SetUserLoggedIn(false);
    throw Error(response.statusText);
  }
};

// Get Profile
export const getProfile = async () => {
  await fetch(urls.profile, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
  })
    .then((response) => CheckError(response))
    .then((actualData) => {
      
      // GA send login event
      GaEventLogin();

      // Set user logged in
      SetUserLoggedIn(true);

      // Set returned user data
      SetUser(actualData);

      // Init
      getInit(actualData.domain_id);
    })
    .catch((error) => console.log(error));
};
