// Imports
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSnapshot } from "valtio";
import { Badge } from "@components";
import { StoreDomain } from "@store";
import {
  TableArrow,
  TableLoader,
  TableNoResults,
  columWidth,
  paginationComponentOptions,
  tableBreakpoints,
} from "@tables";
import { formatAddress, getServiceInfo } from "@utils";

export const TableRequests = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const {
    is_super_domain,
    // available_services
  } = useSnapshot(StoreDomain);

  const colums = [
    {
      name: "Azonosító",
      selector: (row) => `${getServiceInfo(row.service).id}-${row.id}`,
      sortable: true,
      width: columWidth.large,
      style: {
        fontWeight: 500,
        textTransform: "uppercase",
      },
      hide: tableBreakpoints.TL,
    },
    {
      id: "date",
      name: "Dátum",
      selector: (row) => row.created_at,
      sortable: true,
      width: columWidth.dateTime,
      hide: tableBreakpoints.DS,
    },
    {
      id: "domain",
      name: "Domain",
      selector: (row) => row.domain_name,
      sortable: true,
      width: columWidth.large,
      hide: tableBreakpoints.DL,
      omit: !is_super_domain,
    },
    /*
    {
      id: "service",
      name: "Szolgáltatás",
      selector: (row) => row.service,
      sortable: true,
      width: columWidth.large,
      hide: tableBreakpoints.DL,
      omit: available_services.length <= 1,
    },
    */
    {
      name: "Cím",
      selector: (row) => formatAddress(row.resolved_addr),
      sortable: true,
    },
    {
      name: "Státusz",
      selector: (row) => <Badge status={row.status} type="request" />,
      sortable: true,
      width: columWidth.normal,
    },
    {
      cell: (row) => <TableArrow id={row.id} />,
      sortable: true,
      width: columWidth.arrow,
    },
  ];

  // Loading state handling
  useEffect(() => {
    if (data.length > 0) {
      setLoading(false);
    }
  }, [data]);

  return (
    <div className="datatable__table">
      <DataTable
        data={data}
        columns={colums}
        onRowClicked={(row) => {
          window.location.assign(`http://map.envimap.hu/${getServiceInfo(row.service).link}?${getServiceInfo(row.service).id}=${row.id}`);
        }}
        progressPending={loading}
        progressComponent={<TableLoader />}
        defaultSortFieldId="date"
        defaultSortAsc={false}
        noDataComponent={
          <TableNoResults text="Nincsenek a keresésnek megfelelő adatigényei" />
        }
        paginationPerPage={30}
        paginationComponentOptions={paginationComponentOptions}
        responsive
      />
    </div>
  );
};
