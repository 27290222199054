// Imports
import {
  cookieRemoveRememberMe,
  cookieSetRememberMe,
  cookieSetTempPassword,
  cookieSetToken,
  getInit,
  urls,
} from "@apis";
import { SetUser, SetUserLoggedIn } from "@store";

// Check error
const CheckError = (response, message) => {
  if (response.status === 200) {
    return response.json();
  } else {
    // Send error message to user
    message(["error", "Helytelen felhasználónév vagy jelszó."]);
    throw Error(response.statusText);
  }
};

// Login
export const Login = async (body, message) => {
  await fetch(urls.login, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: body.email,
      password: body.password,
    }),
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {
      // Kickout users wo are inactive or in default domain
      if (
        !actualData.is_active ||
        actualData.domain_id === "670a5c9c-1c12-476e-90a0-bbce8bf56d0c"
      ) {
        message(["error", "A felhasználói fiók nincs aktiválva!"]);
        SetUserLoggedIn(false);
      } else {
        // Set temporary password to cookie
        if (actualData.is_temp_password) {
          cookieSetTempPassword(body.password);
        }

        // Store token at cookie
        cookieSetToken(actualData.token);
        // Store/Remove rememberMe from cookie
        if (body.rememberMe) {
          cookieSetRememberMe(body.email, body.password);
        } else {
          cookieRemoveRememberMe();
        }

        // Set returned user data
        SetUser(actualData);
        // Set user logged in
        SetUserLoggedIn(true);

        // Init
        getInit(actualData.domain_id);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
