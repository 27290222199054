// Imports
import { ArrowForward } from "@icons";

export const TableArrow = (props) => {
  return (
    <div className="tableArrow" title={`Megnyitás a térképen TET-${props.id}`}>
      <ArrowForward />
    </div>
  );
};
