// Imports
import { LogoTerran } from "@icons";

// Hardcoded Superdomain specific data
export const SuperDomainData = [
  {
    // Terrán
    domain_id: "db75f7a4-5ae5-48f1-b534-e86b08b91305",
    domainLogo: <LogoTerran />,
  }
];
